<template>
  <div class="content">
    <!-- dialog nuevo channel -->
    <v-row justify="center">
      <v-dialog v-model="createChannel" persistent max-width="600px">
        <!-- <template v-slot:activator="{ on, attrs }">
              <div class="col-md-12" align="right">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="abrir_create_channel()"
                >
                Nuevo
              </v-btn>
              </div>
            </template> -->
        <v-card>
          <v-card-title>
            <span class="text-h5">Nuevo canal de pago</span>
            <v-spacer></v-spacer>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="createChannel = false"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-card-title>
          <div v-if="procensando_datos">
            <loader
              object="#4caf50"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="40"
              disableScrolling="false"
              name="box"
            ></loader>
          </div>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-col cols="12">
                  <v-text-field
                    v-model="channel"
                    :counter="30"
                    label="Medio de pago"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="codigobcra"
                    :items="items"
                    item-text="channels"
                    :item-value="'id'"
                    :rules="[(v) => !!v || 'BCRA es requerido']"
                    label="Código BCRA"
                    required
                  ></v-select>
                </v-col>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="createChannel = false">
              Cerrar
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="dialog"
              color="blue darken-1"
              text
              @click="guardar_channel()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- end dialog nuevo channel -->
    <br />
    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Canales de pago</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde. Volver a
                intentar
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <!-- DATOS DE LA TABLA -->
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="find_in_page"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :search="search"
                :headers="headers"
                :items="desserts"
                :loading="cargando"
                :footer-props="footerProps"
                :items-per-page="rowsPerPage"
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-0 trow"
              >
                <!-- <template v-slot:[`item.CodigoBcra`]="{ item }">
                    {{ traducirBcra(item.CodigoBcra) }}
              </template> -->
                <!-- ACCIONES BOTONES -->
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <!-- modal editar channel  -->
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Editar canal de pago</span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>
                        <div v-if="procensando_datos">
                          <loader
                            object="#4caf50"
                            color1="#ffffff"
                            color2="#17fd3d"
                            size="5"
                            speed="2"
                            bg="#343a40"
                            objectbg="#999793"
                            opacity="40"
                            disableScrolling="false"
                            name="box"
                          ></loader>
                        </div>
                        <v-form ref="form" v-model="valid" lazy-validation>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="nombreedit"
                                    :counter="30"
                                    :rules="namenombre"
                                    label="Canal de pago"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <!-- <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="editcodigobcra"
                                    :counter="30"
                                    :rules="namecodigobcra"
                                    label="Codigo BCRA"
                                    required
                                  ></v-text-field>
                                </v-col> -->

                                <!-- <v-col
                            cols="12"
                            sm="12"
                            md="12"
                           >
                            <v-select
                            v-model="editcodigobcra"
                            :items="items"
                            item-text="channels"
                            :item-value="'id'"
                            :rules="[v => !!v || 'BCRA es requerido']"
                            label="Código BCRA"
                            required
                          ></v-select>
                          </v-col> -->
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="actualizar_channel"
                            >
                              Actualizar
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!-- modal eliminar -->
                    <!-- <v-dialog v-model="dialogDelete" max-width="400px">
                  <v-card>
                    <v-card-title class="text-h5 justify-center" v-text="channelDelete"></v-card-title>
                    <v-card-actions>
                       <div v-if="procensando_datos"> 
                    <loader object="#4caf50" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="40" disableScrolling="false" name="box"></loader>
                    </div>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">Aceptar</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog> -->
                  </v-toolbar>
                </template>
                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon color="orange" @click="editItem(item)">
                    create
                  </v-icon>

                  <!-- <v-icon
                    color="red"
                    @click="deleteItem(item)"
                  >
                    delete
                  </v-icon> -->
                </template>
                <!--END  BOTONES DE LA TABLA -->
                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Channels } from "../../services/channels.js";
export default {
  name: "Channels",
  data: () => ({
    cargando: false,
    dialog: false,
    dialogDelete: false,
    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    //loaders para procesar datos dee envio
    procensando_datos: false,
    // Filter models.
    search: "",
    headers: [
      { text: "Canal de pago", value: "Nombre" },
      //  { text: 'Código Bcra', value: 'CodigoBcra' },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    // Estas propiedades customizan el footer por default(valores por defecto del datatable)
    footerProps: {
      "items-per-page-options": [],
      "items-per-page-text": "",
      "prev-icon": "",
      "next-icon": "",
      "page-text": "",
    },
    // paginacion api
    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },
    //  numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    rowsPerPage: 10,
    desserts: [],

    // nuevo channel POST
    createChannel: false,
    channel: "",
    namenombre: [
      (v) => !!v || "Canal de pago es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "Canal de pago no debe superar los 30 caracteres",
    ],
    namecodigobcra: [
      (v) => !!v || "Codigo bcra es requerido",
      (v) => /^\d+(\.\d{1,2})?$/.test(v) || "Codigo bcra es númerico",
    ],

    codigobcra: null,
    items: [
      { id: 1, channels: "Tarjeta de crédito" },
      { id: 2, channels: "Tarjeta de débito" },
      { id: 3, channels: "Tarjeta pregapa" },
      { id: 4, channels: "Transferencias de fondos" },
      { id: 5, channels: "Efectivo" },
    ],

    // editar channel PUT
    channelID: "",
    channeledit: "",
    nombreedit: "",
    editcodigobcra: "",
    //campos a eliminar
    channelDelete: "",
    // validaciones
    valid: false,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete (val) {
    //   val || this.closeDelete()
    //  },
  },

  created() {
    this.getdatos();
  },

  methods: {
    //listar los channels
    getdatos: function() {
      const params = {
        Number: this.pagination.page,
        Size: this.rowsPerPage,
      };
      this.cargando = true;
      Channels.getChannels(params)
        .then((response) => {
          if (response.data.data == null) {
            this.desserts = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.desserts = response.data.data;
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },
    // paginar
    onPageChange() {
      this.getdatos();
    },
    // registros por paginas
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.pagination.page = 1;
      this.getdatos();
    },
    traducirBcra(value) {
      switch (value) {
        case 1:
          return "Tarjeta de crédito";

        case 2:
          return "Tarjeta de débito";

        case 3:
          return "Tarjeta prepaga";

        case 4:
          return "Transfrencias de fondos";

        case 5:
          return "Efectivo";

        default:
          return value;
      }
    },

    // comfirmar nuevo medio de pago
    async guardar_channel() {
      var payload = {
        Channel: this.channel,
        // CodigoBcra: this.codigobcra,
      };
      this.validate();
      if (this.res === true) {
        this.error = false;
        this.procensando_datos = true;
        await Channels.nuevo_channel(payload)
          .then(() => {
            this.$toastr.s("Medio de pago se creo correctamente");
            // actualizar lista de channel
            this.getdatos();
            // cerrar dialog create channel
            this.createChannel = false;
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    //dialog crear nuevo rubro
    editItem() {
      this.dialog = true;
    },

    //consultar los datos de channel a modificar
    editItem(item) {
      this.consultar_channel(item);
      this.dialog = true;
    },

    //confirmar actualizar el channel
    async actualizar_channel() {
      var payload = {
        id: this.channelID,
        Channel: this.channeledit,
        Nombre: this.nombreedit,
        // CodigoBcra: parseInt(this.editcodigobcra),
      };
      this.validate();
      if (this.res === true) {
        this.error = false;
        this.dialog = true;
        this.procensando_datos = true;
        await Channels.actualizar_channel(payload)
          .then(() => {
            this.$toastr.s("Medio de pago se actualizo correctamente");
            // actualziar tabla de clientes
            this.getdatos();
            this.close();
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
            this.dialog = false;
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    //consultar el id del rubro a eliminar
    // deleteItem (item) {
    //     this.consultar_channel(item);
    //     this.dialogDelete = true
    // },

    //confirmar delete channeñl
    // deleteItemConfirm () {
    //    const params  = {
    //       id : this.channelID,
    //       }
    //       this.procensando_datos = true
    //       Channels.delete_channel(params)
    //       .then( response => {
    //           // mensaje de exito
    //           this.$toastr.s("Medio de pago se elimino con éxito");
    //           // actualziar tabla de clientes
    //            this.getdatos();
    //           }).catch(error => {
    //           this.errored = true
    //           var data = error.response.data;
    //           this.error = true;
    //           this.$toastr.e(data.message);
    //   }).finally(() => this.procensando_datos = false);
    //   this.closeDelete()
    // },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // closeDelete () {
    //   this.dialogDelete = false
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem)
    //     this.editedIndex = -1
    //   })
    // },

    // validar formulario actualizar
    validate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },

    //consultar cliente por id
    consultar_channel: function(item) {
      const params = {
        channel: item.Channel,
      };
      Channels.getChannelName(params)
        .then((response) => {
          this.channelDelete =
            "¿ Desea eliminar medio de pago " + response.data.Channel + "?";
          //cargar los datos en el modal
          this.channelID = response.data.Id;
          this.nombreedit = response.data.Nombre;
          this.channeledit = response.data.Channel;
          // this.editcodigobcra = response.data.CodigoBcra;
          // if(response.data.CodigoBcra == 1){
          //  this.editcodigobcra =  { id: 1, channels: 'Tarjeta de crédito'};
          // }else if (response.data.CodigoBcra == 2){
          //  this.editcodigobcra =  { id:2, channels: 'Tarjeta de débito'};
          // }
          // else if (response.data.CodigoBcra == 3){
          //  this.editcodigobcra =  { id:3, channels: 'Tarjeta pregapa'};
          // }
          // else if (response.data.CodigoBcra == 4){
          //  this.editcodigobcra =  { id:4, channels: 'Transferencias de fondos'};
          // }
          // else{
          //  this.editcodigobcra =  { id:5, channels: 'Efectivo'};
          // }
        })
        .catch((error) => {
          var data = error.response.data;
          //mostrar mensaje de error en caso que la variable sea true(tenga datos)
          this.error = true;
          this.msg = data.message;
          this.$toastr.e(data.message);
        });
    },
  },
};
</script>
<style scoped>
/* efecto tabla de cliente */
/* .trow {
  transition: transform 0.3s;
}
.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
} */

a {
  color: #f7f7f7;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
</style>
